.particle-style {
  position: absolute;
  right: 0; 
  bottom: 40%;
  min-width: 100%; 
  min-height: 50%;
  width: auto; 
  height: 10%;
  z-index: 0;
  
}
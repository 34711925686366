.my-idea {
  display: inline-block;
  position: relative;
  text-align: center;
  align-items: center;
  width: 100%;
  color: #383736;
  overflow-x: hidden;
}

.my-idea__header {
  height: 40px; 
  margin-top: -16px;
  margin-bottom: 16px;
  border: 0px solid red;
}

.my-idea__card-heading {
  border: 0px solid red;
  font-weight: bold;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.my-idea__name {
  display: inline-block;
  text-align: left;
  padding-top: 20px;
}

.my-idea__card-header {
  display: inline-block;
  text-align: left;
  font-size: 1.1rem !important;
  border: 0px solid red;
  width: 87%;
}

.my-idea__card-subheader {
  display: inline-block;
  text-align: left;
  font-size: 1rem !important;
}

.my-idea__list-title { 
  border: 0px solid blue;
  width: 100%;
}

.right { 
  float: right;
}

.my-idea__card-skill {
  font-size: 1rem !important;
}

.my-idea__icon {
  color: var(--orange);
  margin: 0px;
  cursor: pointer;
  border: 0px solid blue;
  font-size: 10px;
}

.buffer { 
  margin: 16px;
}

.smallBuffer {
  margin-left: 30px;
}

.avatar {
  display: inline-grid;
  margin: 10px;
  width: 80px !important;
  height: 80px !important;
  border: 1px solid #f2f2f2;
  float: left;
}

.my-idea-list {
  width: 100%;
}

.my-idea-list__equity {
  color: orange;
  font-size: 1rem;
  font-weight: normal;
  width: 90%;
}

.my-idea-list__header {
  color: #383736;
  font-size: 12px;
  text-transform: capitalize;
}

.my-idea-list td {
  padding: 0.2rem 0.4rem;
  vertical-align: middle;
  border-bottom: 1px solid #f2f2f2;
}

.my-idea td:first-child {
  width: 5%;
}

.public_button {
  background-color: #fff;
  border: 1px solid var(--orange);
  border-radius: 5px;
  padding: 6px;
}

.public_button:hover {
  background-color: #eda95b62;
}
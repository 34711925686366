/* 
/**
 * Copyright 2017 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* .firebaseui-container {
  background: rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
  min-height: 150px;
  padding-top: 10px;
  border-radius: 20px;
  box-shadow: none;
}
.firebaseui-container.firebaseui-page-provider-sign-in {
  background: transparent;
  box-shadow: none;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}
.firebaseui-container.firebaseui-id-page-callback {
  background: transparent;
  box-shadow: none;
  margin-top: 40px;
  height: 84px;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}
.firebaseui-card-header {
  display: none;
}
.firebaseui-subtitle, .firebaseui-text {
  color: rgba(255, 255, 255, 0.87);
}
.firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
  background: rgba(0, 0, 0, 0.1);
}
.firebaseui-id-dismiss-info-bar {
  display: block;
}
.firebaseui-info-bar {
  border: 0;
  border-radius: 10px;
  left: 5%;
  right: 5%;
  top: 10%;
  bottom: 10%;
} */ 
.firebaseui-container {
    background: rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
    min-height: 150px;
    padding-top: 10px;
    border-radius: 20px;
    box-shadow: none;
  }
  .firebaseui-container.firebaseui-page-provider-sign-in {
    background: transparent;
    box-shadow: none;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
  }
  .firebaseui-container.firebaseui-id-page-callback {
    background: transparent;
    box-shadow: none;
    margin-top: 40px;
    height: 84px;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
  }
  .firebaseui-card-header {
    display: none;
  }
  .firebaseui-subtitle, .firebaseui-text {
    color: rgba(255, 255, 255, 0.87);
  }
  .firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
    background: rgba(0, 0, 0, 0.1);
  }
  .firebaseui-id-dismiss-info-bar {
    display: block;
  }
  .firebaseui-info-bar {
    border: 0;
    border-radius: 10px;
    left: 5%;
    right: 5%;
    top: 10%;
    bottom: 10%;
  } 

.firebaseui-idp-button {
    direction: ltr;
    font-weight: 500;
    height: auto;
    line-height: normal;
    max-width: 220px !important; 
    border-radius: 0.5rem !important;
    min-height: 40px;
    padding: 8px 16px;
    text-align: left;
    width: 100%;

}


.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 20px 0 20px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 0;
  margin-left: 0;
}

.collab {
  width: 48%;
}

.coin {
  width: 48%;
}

.form-logo {
  margin: 15px;
  width: 30px;
  height: 40px;
}

.date {
  display: flex;
  justify-content: space-around;
  margin-top: 15;
}

.helpMsg {
  font-size: 12px;
  margin-bottom: 0;
  margin-left: 2;
  margin-top: 8;
  display: inline-block;
  width: 100%;
}

.menu {
  width: 200px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.dialog-title {
  font-size: 1.5rem;
}

.assignArrow{
  color: var(--orange);
}

@media (max-width: 400px) {
 
  .date {
    display: block;
    text-align: center;
    margin-top: 20px;
  }
  .row {
    display: block;
    text-align: center;
  }

  .collab {
    width: 100%;
  }

  .coin {
    width: 100%;
  }

  .dialog-title {
    font-size: 1.1rem;
  }

  .assignArrow{
    display: none;
  }
  
}

.Signup-style {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  position: relative;
  z-index: 1;
}

.signup-card-style {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
  color: #000;
  z-index: 5;
}

.Login-style {
  height: 100%;
  min-height: 100vh;
  /*background-image: linear-gradient(
      to top,
      rgba(16, 21, 32, 0.7),
      rgba(45, 39, 66, 0.7)
    ),
    url(../../static/bg6.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;*/
  background-color: linear-gradient(
    to top,
    rgba(16, 21, 32, 0.7),
    rgba(45, 39, 66, 0.7)
  );
  position: relative;
  z-index: 3;
}

.login-card-style {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
  color: #000;
  z-index: 3;
}

.forgot_pw {
  padding-top: 20px;
  float: right;
  color: var(--orange) !important;
  cursor: pointer;
  display: flex;
}

.forgot_pw:hover {
  color: #383736 !important;
}

.form_error {
  color: red !important;
}

.formHelpMessage {
  padding-top: 10px;
}
/* Shared */
.loginBtn {
  height: 36px;
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  margin-left: 3.5%;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  white-space: nowrap;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px 0px;
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.54);
  border-radius: 4px;
  text-align: center;
}
.loginBtn--google:before {
  background: url("./icons8-google.svg") 6px 6px no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
  transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s,
    box-shadow 0.218s ease 0s;
  /* background: #e74b37; */
}

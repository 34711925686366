/* Idea List */
.equity-list {
  width: 100%;
}

.equity-list td {
  padding: 0.2rem 0.4rem;
  vertical-align: middle;
  border-bottom: 1px solid #f2f2f2;
}

.equity-list td:first-child {
  width: 60%;
}

.equity-list__av {
  height: 20px !important;
  width: 20px !important;
}

.equity-list__name {
  color: #383736;
  font-weight: normal;
  font-size: 0.8rem;
  padding: 4px 0px;
}

.equity-list__collaborators {
  text-align: center; 
}

.equity-list__status {
  color: orange;
  font-size: 0.8rem;
  font-weight: normal;
}

.equity-list__id {
  color: #383736;
  font-size: 10px;
}

.equity-list__header {
  color: #383736;
  font-size: 12px;
  text-transform: capitalize;
}

.equity-list__expand {
  width: 25%;
}

.sprxs-idea-list-wrapper {
  margin: 0px auto;
  padding: auto 10px;
  height: 44px;
}

.equity-list__button {
  margin-left: 6px;
  color: var(--orange);
  cursor: pointer;
}

.equity-list__title{
  font-size: 1rem;
  text-align: left;
  color: #383736;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.equity-list__wrapper{
  width: 100%;
}

@media (min-width: 1280px) {
  .equity-list__name {
    font-size: 0.9rem;
  }
  .equity-list__status {
    color: orange;
    font-size: 0.9rem;
    font-weight: normal;
  }
  .equity-list__av {
    height: 40px !important;
    width: 40px !important;
  }
}

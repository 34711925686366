.footerContainer {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-rows: max-content max-content;
    grid-template-columns: max-content 1fr max-content;
    width: 100vw;
    max-width: 100%;
    height: max-content;
    background-color: #1a1c1e;
}

.footerSection {
    padding: 10px;
    text-align: right;
    color: grey;
    font-size: 12px;
}

.footerSection p {
    margin: 0px;
    padding: 0px;
}

.footerSection img {
    padding: 5px;
    max-width: 80px;
    height: auto;
}

.footerSection ul {
    list-style-type: none;
    padding-top: 10px;
}

.fill {
    grid-row: span 2;
    text-align: left;
}

.footerEnd {
    margin-right: 0%;
}

.iconHover {
    margin-right: 10px;
}

.iconHover:hover {
    color: var(--orange);
    cursor: "pointer"
}

.footerSection a {
    color: grey;
}

.footerSection a:hover {
    color: var(--orange);
    cursor: pointer;
    text-decoration: none;
}

@media only screen and (max-width: 599px) {
    .footerContainer {
        grid-template-columns: 80px 1fr 140px;
    }

    .footerSection img {
        padding: 5px;
        margin-left: 0px;
        max-width: 70px;
        height: auto;
    }

    .footerEnd {
        margin-right: 0px;

    }
}

@media only screen and (min-width: 600px) {
    .footerContainer {
        grid-template-columns: 30% 1fr 40%;
    }

    .footerSection img {
        padding: 5px;
        margin-left: 20%;
        max-width: 120px;
        height: auto;
    }

    .footerEnd {
        margin-right: 20%;
    }
}

@media only screen and (min-width: 900px) {
    .footerContainer {
        grid-template-columns: 30% 1fr 40%;
    }

    .footerSection img {
        padding: 5px;
        margin-left: 30%;
        max-width: 120px;
        height: auto;
    }
}
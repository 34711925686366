.header {
  background-color: rgba(255, 255, 255, 0.9);
  box-Shadow: none;
  border-bottom: 1px solid rgba(239, 132, 6, 0.3);
  height: 60px;
  width: 100%;
  float: left;
  position: fixed;
  z-index: 100;
}

.homeNavWrapper {
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.menuIcons {
  margin-left: 20px;
  margin-top: 10px;
  display: inline-block;
  width: 200px;
  height: max-content;
}

.menuIcons span {
  margin-left: 0px;
  margin-top: 0px;
  display: inline-block;
  text-align: left;
}

.blockChainIcon {
  float: left;
  margin-left: 0px;
  margin-top: 0px;
}

.blockChainText {
  font-size: 10px;
  color: #383736;
  margin-top: 26px;
  margin-left: 0px;
  white-space: nowrap;
}

.logoBtn {
  float: left;
  margin-right: 10px;
  cursor: pointer;
}

.navLinks {
  font-size: 14px;
  line-height: 58px;
  margin-right: 20px;
}

.navLinks a {
  display: inline-block;
  color: var(--orange);
  text-align: center;
  padding: 0px 16px;
  text-decoration: none;
}

.navLinks a:hover {
  cursor: pointer;
  text-decoration: none;
  color: #383736;
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.3);
}

.navLinks a.special {
  color: #1686cc;
}

.navLinks a.special:hover {
  color: #383736;
}

.navLinksMobile {
  flex-grow: 1;
  width: 15%;
  float: right;
  line-height: 60px;
  display: none;
  color: #383736;
}

.homeMobileMenu .special {
  flex-grow: 1;
  width: 15%;
  float: right;
  line-height: 60px;
  display: inline;
  color: #1686cc;
}

.homeMobileMenu {
  margin-top: 40px;
  z-index: 99999999999;
}


@media only screen and (max-width: 600px) {
  .navLinks {
    display: none;
  }

  .navLinksMobile {
    display: block;
  }
}

@media only screen and (min-width: 600px) {}

@media only screen and (min-width: 900px) {
  .navLinks {
    font-size: 16px;
  }

}

@media only screen and (min-width: 1200px) {}
.gridContainer {
  display: grid;
  justify-content: center;
  overflow-X: hidden;
  width: 100vw;
  max-width: 100%;
  position: relative
}

.gridSection {
  text-align: center;
  align-content: center;
  vertical-align: bottom;
  height: auto;
  width: 100%;
  overflow-y: hidden;
}

.gridSection button {
  border: 0px solid #383736;
  border-radius: 22px;
  background: #1686CC;
  color: #fff;
  padding: 10px 20px 10px 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  transition:  0.2s ease-in-out;
}

.gridSection button:hover{
  transform: scale(1);
  background: var(--orange);
}

.gridInnerSection {
  justify-content: center;
  grid-template-rows: auto;
  text-align: center;
  height: auto;
  width: auto;
}

.gridSectionHeader {
  display: grid;
  height: 100vh;
  padding-top: 8vh;
  position: relative;
  grid-template-rows: max-content max-content 140px;
  overflow: hidden;
}

.sectionAltColor {
  background: #f2f2f2;
}

.gridSection100 {
  position: relative;
  height: auto;
}

.gridSection20 {
  height: auto;
  justify-content: center;
}

.gridSection80 {
  height: auto;
  justify-content: center;
}

.sectionContainer {
  display: grid;
  height: 100%;
  grid-template-rows: 12vh 12vh 1fr 110px;
}

.sectionContainerMin {
  display: grid;
  height: 100%;
  grid-template-rows: 100px 100px max-content 100px;
}

.gridSection3Col {
  display: grid;
  grid-auto-rows: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
}

.gridSection2Row {
  display: grid;
  grid-auto-rows: max-content 1fr;
  justify-content: center;
  align-items: center;
}

.gridSectionHeader .title {
  color: #383736;
  font-size: 18px;
  width: auto;
  font-weight: 1;
  letter-spacing: 6px;
  padding: 0px 0px 80px 0px;
  margin: 0px 40px;
}



.gridSectionHeader .__button {
  font-size: 18px;
  width: 240px;
  letter-spacing: 4px;
  color: #383736;
  cursor: pointer;
  transition: 0.5s;
}

.gridSectionHeader .__button:hover {
  color:var(--orange);
  cursor: pointer;
}

.sectionTitle {
  font-size: 32px;
  font-weight: 1;
  text-align: center;
  letter-spacing: 5px;
  color: var(--orange);
}

.sectionSpan {
  left: 50%;
  width: 24px;
  bottom: 11vh;
  height: 25px;
  opacity: 0.4;
  position: absolute;
  transform: rotate(-45deg);
  box-sizing: border-box;
  margin-left: -12px;
  border-left: 1px solid #383736;
  border-bottom: 1px solid #383736;
  transition: 0.5;
  display: none;
}

.sectionSpan:hover {
  border-color: var(--orange);
  cursor: pointer;
}

.logoImage {
  max-width: 280px;
  height: auto;
  padding-top: 3em;
  padding-bottom: 1em;
}

#sprxsLogo .spark {
  stroke: deepskyblue;
  stroke-width: 2;
}

/* Phone */

@media only screen and (max-width: 320px) {
  html,
  body {
    overflow: visible;
  }

  .gridSectionHeader {
    grid-template-rows: max-content max-content 100px;
  }

  .gridSectionHeader .gridSection3Col {
    grid-template-rows: auto;
  }

  .sectionContainerMin {
    grid-template-rows: 40px max-content max-content 100px;
  }

  .gridSectionHeader .title {
    font-size: 14px;
    letter-spacing: 3px;
  }

  .sectionSpan {
    bottom: 12%;
  }

  .logoImage {
    max-width: 280px;
    padding-top: 4em;
    padding-bottom: 1em;
  }

  .sectionTitle {
    font-size: 24px;
  }
}


@media only screen and (min-width: 321px) and (max-width: 599px) {
  html,
  body {
    overflow: visible;
  }

  .gridSectionHeader {
    grid-template-rows: max-content max-content 140px;
  }

  .gridSectionHeader .gridSection3Col {
    grid-template-rows: auto;
  }

  .sectionContainerMin {
    grid-template-rows: 40px max-content max-content 100px;
  }

  .gridSectionHeader .title {
    font-size: 14px;
    letter-spacing: 3px;
  }

  .sectionSpan {
    bottom: 12%;
  }

  .logoImage {
    max-width: 280px;
    padding-top: 6em;
    padding-bottom: 1em;
  }

  .sectionTitle {
    font-size: 24px;
  }
}

@media only screen and (max-width: 599px) and (max-height: 320px) {
  .logoImage {
    max-width: 180px;
    padding-top: 4em;
    padding-bottom: 1em;
  }

  .gridSectionHeader .title {
    padding-bottom: 0px;
  }

  .gridSectionHeader .gridSection3Col {
    grid-template-columns: auto auto auto;
  }
}

/* Phone */
@media only screen and (min-width: 600px) {
  .gridSectionHeader {
    grid-template-rows: max-content max-content max-content;
  }

  .gridSectionHeader .gridSection3Col {
    grid-template-rows: auto;
  }

  .sectionContainerMin {
    grid-template-rows: 10vh 8vh max-content 100px;
  }

  .gridSectionHeader .title {
    font-size: 21px;
    font-weight: 1;
    letter-spacing: 8px;
    margin-bottom: 0px;
  }

  .logoImage {
    max-width: 380px;
    padding-top: 6em;
    padding-bottom: 1em;
  }

  .sectionTitle {
    font-size: 24px;
  }
}

@media only screen and (min-width: 600px) and (max-height: 380px) {
  .logoImage {
    max-width: 180px;
    padding-top: 4em;
    padding-bottom: 1em;
  }

  .gridSectionHeader .title {
    padding-bottom: 0px;
  }

  .gridSectionHeader .gridSection3Col {
    grid-template-columns: auto auto auto;
  }
}

@media only screen and (min-width: 900px) {
  .gridSectionHeader {
    grid-template-rows: max-content 6vh max-content;
    padding-top: 6vh;
  }

  .gridSection3Col {
    grid-template-columns: max-content max-content max-content;
  }

  .sectionContainer {
    display: grid;
    height: 100%;
    grid-template-rows: 14vh 8vh 1fr 8vh;
  }

  .sectionContainerMin {
    grid-template-rows: 14vh 10vh max-content 18vh;
  }

  .logoImage {
    max-width: 320px;
    padding-top: 12vh;
  }

  .gridSectionHeader .title {
    font-size: 18px;
    font-weight: 1;
    letter-spacing: 10px;
  }

  .video {
    grid-template-rows: 14vh 8vh max-content 110px;
  }
}

@media only screen and (min-width: 700px) and (max-height: 420px) {
  .logoImage {
    max-width: 180px;
    padding-top: 3em;
    padding-bottom: 1em;
  }

  .gridSectionHeader .title {
    padding-bottom: 0px;
  }

  .gridSectionHeader .gridSection3Col {
    grid-template-columns: auto auto auto;
  }
}

@media only screen and (min-width: 1200px) {
  .gridSectionHeader {
    grid-template-rows: max-content 6vh max-content;
    padding-top: 4vh;
  }

  .gridSection3Col {
    grid-template-columns: max-content max-content max-content;
  }

  .logoImage {
    max-width: 320px;
    padding-top: 9vh;
  }

  .sectionSpan {
    display: inline;
  }

  .gridSectionHeader .title {
    font-size: 18px;
    font-weight: 1;
    letter-spacing: 10px;
  }

  .gridSection100 {
    position: relative;
    height: 100vh;
  }

  .sectionContainer {
    display: grid;
    height: 100%;
    grid-template-rows: 14vh 10vh 1fr 8vh;
  }

  .sectionContainerMin {
    grid-template-rows: 14vh 10vh max-content 18vh;
  }

  .video {
    grid-template-rows: 12vh 10vh max-content 110px;
  }
}

@media only screen and (min-width: 1400px) {
  .gridSectionHeader {
    grid-template-rows: max-content 6vh max-content;
    padding-top: 6vh;
  }

  .gridSection3Col {
    grid-template-columns: max-content max-content max-content;
  }

  .logoImage {
    max-width: 420px;
    padding-top: 12vh;
  }

  .sectionSpan {
    display: inline;
  }

  .gridSectionHeader .title {
    font-size: 18px;
    font-weight: 1;
    letter-spacing: 10px;
  }

  .gridSection100 {
    position: relative;
    height: 100vh;
  }

  .sectionContainer {
    display: grid;
    height: 100%;
    grid-template-rows: 14vh 10vh 1fr 8vh;
  }

  .sectionContainerMin {
    grid-template-rows: 14vh 10vh max-content 18vh;
  }

  .video {
    grid-template-rows: 12vh 10vh max-content 110px;
  }
}

@media only screen and (min-width: 1400px) and (min-height: 901px) {
  .gridSectionHeader {
    grid-template-rows: max-content max-content max-content;
    padding-top: 6vh;
  }

  .gridSection3Col {
    grid-template-columns: max-content max-content max-content;
  }

  .logoImage {
    max-width: 480px;
    padding-top: 12vh;
  }

  .sectionSpan {
    display: inline;
  }

  .gridSectionHeader .title {
    font-size: 24px;
    font-weight: 1;
    letter-spacing: 10px;
  }

  .gridSection100 {
    position: relative;
    height: 100vh;
  }

  .sectionContainer {
    display: grid;
    height: 100%;
    grid-template-rows: 14vh 18vh 1fr 8vh;
  }

  .sectionContainerMin {
    grid-template-rows: 14vh 18vh max-content 18vh;
  }

  .sectionTitle {
    font-size: 36px;
    font-weight: 1;
    text-align: center;
    letter-spacing: 5px;
    color: var(--orange);
  }

  .video {
    grid-template-rows: 14vh 12vh max-content 18vh;
  }
}

@media only screen and (min-width: 1400px) and (max-height: 900px) {
  .gridSectionHeader {
    grid-template-rows: max-content max-content max-content;
    padding-top: 3vh;
  }

  .logoImage {
    max-width: 420px;
    padding-top: 10vh;
  }
}
@font-face {
  font-family: "sprxs";
  src: url("/fonts/sprxs.ttf?12a213") format("truetype"),
    url("/fonts/sprxs.woff?12a213") format("woff"),
    url("/fonts/sprxs.svg?12a213#sprxs") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="sprxs-"],
[class*=" sprxs-"] {
  /* use !important to prevent issues with browser extensions that change static/fonts */
  font-family: "sprxs" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sprxs-library:before {
  content: "\e921";
}
.sprxs-coin-dollar:before {
  content: "\e93b";
}
.sprxs-hammer2:before {
  content: "\e9a8";
}
.sprxs-question:before {
  content: "\ea09";
}
.sprxs-embed2:before {
  content: "\ea80";
}
.sprxs-svg:before {
  content: "\eae9";
}

.sprxs-fav100x100:before {
  content: "\e900";
  font-size: 3.6rem;
  color: var(--orange);
}
.sprxs-fav100x100logo:before {
  content: "\e901";
}
.sprxs-gender-neutral:before {
  content: "\e902";
  font-size: 2.5rem;
  color: var(--orange);
}
.sprxs-screen-full:before {
  content: "\e903";
}

.sprxs-edit:before {
  content: "\e910";
}
.sprxs-globw:before {
  content: "\e911";
}
.sprxs-attach:before {
  content: "\e912";
}
.sprxs-bubbles:before {
  content: "\e96c";
}

.navcontainer:hover img,
.navcontainer:hover p {
  opacity: 1;
}

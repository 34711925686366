:root {
  --gray: #7c7c7c;
}

.dashboard-wrapper {
  padding-top: 4.5rem;
  color:#383736;
}

.dasboard__icon-button {
  color: var(--orange);
  cursor: pointer;
}


.sprxs-screen-full {
  padding: 0 1rem;
  display: flex;
  /* align-items: center; */
  cursor: move;
}

.dashboard {
  border: 1px solid var(--gray);
  margin: 1rem;
  list-style-type: none;
  border-radius: 10px;
}

[class^="dashboard__item--"] {
  background-color: var(--white);
  overflow: hidden;
  border: 1px solid var(--gray);
  margin: 0.5rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.dashboard__title-wrapper {
  /* color: var(--white); */
  border-bottom: 1px solid var(--gray);
  display: flex;
  justify-content: space-between;
}

.dashboard__title,
.dashboard__title-wrapper {
  min-height: 40px;
  height: 40px;
}

.dashboard__body {
  padding: 0.5rem;
}

.dashboard__title,
.dashboard__body {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
}

.dashboard__title {
  /* align-items: center; */
  padding: 0.5rem 1rem;
}
.dashboard__title span {
  color: var(--orange);
  font-size: 1.1rem;
  margin-top: -1px;
  font-weight: normal;
  text-transform: uppercase;
}
.dashboard__body {
  overflow-y: auto;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.idea-card {
  display: block;
}

.dashboard__item--my-collaborations .dashboard__item--my-idea .dashboard__body {
  max-height: 100%;
}

.dashboard__action-button--text,
.dashboard__action-button--icon {
  background-color: transparent;
  border: 0;
  padding: 5px;
}
.dashboard__action-button--icon {
  font-size: 24px;
  border-radius: 50%;
}
.dashboard__item--my-ideas .dashboard__search-text,
.dashboard__item--my-ideas .dashboard__action-button--text {
  transition: 0.2s;
  position: absolute;
  right: 0;
  bottom: 5px;
}
.dashboard__item--my-ideas .dashboard__search-text--on {
  right: 170px;
  z-index: 1;
}
.dashboard__search-text,
.dashboard__action-button--text {
  margin: 0 10px;
  padding: 0 20px;
  border-radius: 16px;
  height: 32px;
  line-height: 1;
  font-size: 18px;
  border: 1px solid var(--orange);
  width: 200px;
  z-index: 2;
  background-color: #fff;
}
.dashboard__footer {
  display: flex;
  justify-content: flex-end;
  padding: 5px 10px;
  align-items: center;
  position: relative;
}

.dashboard__body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.dashboard__body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
.dashboard__body::-webkit-scrollbar-thumb {
  background-color: #f90;
}

#permalink_section {
    white-space: pre-wrap; /* css-3 */    
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */    
    white-space: -o-pre-wrap; /* Opera 7 */    
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}

@media (min-width: 768px) {

  .dashboard-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    height: 100vh;
  }
  .dashboard__item--my-ideas {
    grid-column: 2/3;
    grid-row: 1/2;
  }

  .dashboard__item--rtwo-ideas {
    grid-column: 2/3;
    grid-row: 2/3;
  }

  .dashboard__item--my-profile {
    grid-column: 1/2;
    grid-row: 1/4;
  }
  .dashboard__item--my-wallet {
    grid-column: 2/3;
    grid-row: 3/4;
  }
  .dashboard__item--my-wallet-collab {
    grid-column: 1/2;
    grid-row: 4/4;
  }

  .dashboard__item--my-collaborations {
    grid-column: 2/3;
    grid-row: 3/3;
  }
  .dashboard__item--collab-request {
    grid-column: 2/3;
    grid-row: 4/4;
  }

  div.dashboard__title,
  div.dashboard__body {
    flex-direction: row;
  }
  div.dashboard__body {
    padding: 1rem;
  }
}
@media (min-width: 1280px) {
  .dashboard-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    height: 100vh;
  }
  .dashboard__item--my-ideas {
    grid-column: 2/3;
    grid-row: 1/3;
  }

  .dashboard__item--rtwo-ideas {
    grid-column: 2/3;
    grid-row: 3/5;
  }

  .dashboard__item--my-profile {
    grid-column: 1/2;
    grid-row: 1/4;
  }
  .dashboard__item--my-wallet {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  .dashboard__item--my-wallet-collab {
    grid-column: 1/2;
    grid-row: 4/4;
  }

  .dashboard__item--my-collaborations {
    grid-column: 3/4;
    grid-row: 1/3;
  }
  .dashboard__item--collab-request {
    grid-column: 3/4;
    grid-row: 3/5;
  }
}

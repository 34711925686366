.my-profileEdit {
  display: inline-block;
  position: relative;
  text-align: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  color: #383736;
}

.my-profileEdit__header {
  height: 100px; 
  margin-bottom: 20px;
}

.my-profileEdit__card-heading {
  padding-bottom: 0px !important;
}

.my-profileEdit__name {
  display: inline-block;
  text-align: left;
  padding-top: 20px;
}

.my-profileEdit__card-header {
  font-size: 1.1rem !important;
}

.my-profileEdit__card-subheader {
  font-size: 1rem !important;
}

.my-profileEdit__card-skill {
  font-size: 1rem !important;
}

.my-profileEdit__icon {
  color: var(--orange);
  margin: 16px;
  cursor: pointer;
}

.save_button {
  background-color: #fff;
  border: 1px solid var(--orange);
  border-radius: 5px;
  padding: 6px;
}

.save_button:hover {
  background-color: #eda95b62;
}
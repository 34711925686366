:root {
  --gray: #7c7c7c;
}

.idea-dashboard {
  list-style-type: none;
  color:#383736;
  background-color: #f2f2f2;
  padding: 0px !important;
}

.idea-dashboard-wrapper {
  padding-top: 0rem;
  width: 100%;
}

.idea-dasboard__icon-button {
  color: var(--orange);
  cursor: pointer;
}

.code_white {
  color: #383736;
}

.idea-dashboard__close {
  padding: 10px;
  text-align: right;
}

.sprxs-screen-full {
  padding: 0 0rem;
  display: flex;
  cursor: move;
}

[class^="idea-dashboard__item--"] {
  background-color: var(--white);
  overflow: hidden;
  border: 1px solid var(--gray);
  margin: 0.5rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.idea-dashboard__title-wrapper {
  border-bottom: 1px solid var(--gray);
  display: flex;
  justify-content: space-between;
}

.idea-dashboard__title,
.idea-dashboard__title-wrapper {
  min-height: 40px;
  height: 40px;
}

.idea-dashboard__body {
  padding: 0.5rem;
}

.idea-dashboard__title,
.idea-dashboard__body {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.idea-dashboard__title {
  padding: 0.5rem 1rem;
}

.idea-dashboard__title span {
  color: var(--orange);
  font-size: 1.1rem;
  margin-top: -1px;
  font-weight: normal;
  text-transform: uppercase;
}
.idea-dashboard__body {
  overflow-y: auto;
}

.idea-card, .idea-milstones {
  display: block;
}

.idea-dashboard__item--my-collaborations .idea-dashboard__item--my-idea .idea-dashboard__body {
  max-height: 100%;
}

.idea-dashboard__action-button--text,
.idea-dashboard__action-button--icon {
  background-color: transparent;
  border: 0;
  padding: 5px;
}
.idea-dashboard__action-button--icon {
  font-size: 24px;
  border-radius: 50%;
}
.idea-dashboard__item--my-ideas .idea-dashboard__search-text,
.idea-dashboard__item--my-ideas .idea-dashboard__action-button--text {
  transition: 0.2s;
  position: absolute;
  right: 0;
  bottom: 5px;
}
.idea-dashboard__item--my-ideas .idea-dashboard__search-text--on {
  right: 170px;
  z-index: 1;
}
.idea-dashboard__search-text,
.idea-dashboard__action-button--text {
  margin: 0 10px;
  padding: 0 20px;
  border-radius: 16px;
  height: 32px;
  line-height: 1;
  font-size: 18px;
  border: 1px solid var(--orange);
  width: 200px;
  z-index: 2;
  background-color: #fff;
}
.idea-dashboard__footer {
  display: flex;
  justify-content: flex-end;
  padding: 5px 10px;
  position: relative;
}

.idea-dashboard__body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.idea-dashboard__body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
.idea-dashboard__body::-webkit-scrollbar-thumb {
  background-color: #f90;
}

#permalink_section {
    white-space: pre-wrap; /* css-3 */    
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */    
    white-space: -o-pre-wrap; /* Opera 7 */    
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.idea-dashboard__item--my-collaborations .idea-dashboard__body {
  max-height: 0%;
  flex-direction: column;
}
@media (min-width: 768px) {
  .idea-dashboard__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .idea-dashboard__item--my-wallet {
    grid-column: 1/2;
    grid-row: 1/1;
  }
  .idea-dashboard__item--milstones {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .idea-dashboard__item--my-idea {
    grid-column: 2/3;
    grid-row: 1/3;
  }
  div.idea-dashboard__title,
  div.idea-dashboard__body {
    flex-direction: row;
  }
  div.idea-dashboard__body {
    padding: 1rem;
  }
}
@media (min-width: 1024px) {
  .idea-dashboard-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: 80vh;
  }

  .idea-dashboard__item--my-wallet {
    grid-column: 1/2;
    grid-row: 1/3;
  }

  .idea-dashboard__item--milstones {
    grid-column: 3/4;
    grid-row: 1/3;
  }

  .idea-dashboard__item--my-idea {
    grid-column: 2/3;
    grid-row: 1/3;
  }
}

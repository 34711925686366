.legalWrapper{
  color: black;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 5%;
  margin-bottom: 5%;
  color:  #383736;
}
.legalHeader{
  padding-bottom: 40px;
}

.legalNav {
  margin-top: 80px;
  color: #383736;
  width: 100%;
  text-align: center;
  text-align: center;
  text-align: center;
  display: block;
}

.red{
  color: red;
}

.legalContent ul, ol{
  list-style-position: outside;
  padding: 8px 20px 0px 20px;
}

.legalContent ul {
  list-style-type: disc;
}

.legalContent ol li {
  padding: 6px 0px;
}

.legalContent .legalAlt {
  list-style-type: lower-roman;
}

.legalContent .legalAlpha {
  list-style-type: lower-alpha;
}

.legalSubHead {
  font-size: 24px;
  color: var(--orange);
}
.legalContent span {
  font-weight: 600;
}

.legalContent .copySubHead {
  content: "";
  display: block;
  padding: 8px 0px;
}

.legalContent .highLight{
  color: var(--orange);
}

.legalCopy{

}

.legalModal .legalWrapper{
  margin-left: 0%;
  margin-right: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
}

.legalModal .legalHeader{
  padding-bottom: 40px;
  font-size: 30px;
}

@media only screen and (max-width: 700px) {
  .legalWrapper{
    margin-left: 4%;
    margin-right: 4%;
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 12px;
  }
  .legalHeader{
    padding-bottom: 20px;
    font-size: 18px;
  }

  .legalSubHead {
    font-size: 16px;
  }

  .legalContent ul, ol{
    padding: 4px 14px 0px 24px;
  }
  
  .legalContent ol li {
    padding: 4px 0px;
  }

  .legalContent .copySubHead {
    padding: 4px 0px;
  }
}

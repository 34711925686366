.covidpagetitle {
  display: inline-grid;
  width: 100%;
  background-color: var(--red);
  border: 2px solid #fff;
  padding: 0% 2%;
  margin-bottom: 2%;
  border-radius: 4px;
  /* border: 2px solid #fff; */
  color: #fff;
  /* padding: 14px 12px;
    width: 140px; */
  text-align: center;
  justify-content: center;
  text-decoration: none;
  font-size: 8vh;
}

@media only screen and (max-width: 320px) {
  .covidpagetitle {
    font-size: 3.5vh;
  }
}

@media only screen and (min-width: 321px) and (max-width: 599px) {
  .covidpagetitle {
    font-size: 2.8vh;
  }
}

@media only screen and (max-width: 599px) and (max-height: 320px) {
  .covidpagetitle {
    font-size: 7.6vh;
  }
}

/* Phone */
@media only screen and (min-width: 600px) {
  .covidpagetitle {
    font-size: 2.6vh;
  }
}

@media only screen and (min-width: 600px) and (max-height: 380px) {
  .covidpagetitle {
    font-size: 2.8vh;
  }
}

@media only screen and (min-width: 900px) {
  .covidpagetitle {
    font-size: 2.5vh;
  }
}

@media only screen and (min-width: 700px) and (max-height: 420px) {
  .covidpagetitle {
    font-size: 7.8vh;
  }
}

@media only screen and (min-width: 1200px) {
  .covidpagetitle {
    font-size: 7.5vh;
  }
}

@media only screen and (min-width: 1400px) {
  .covidpagetitle {
    font-size: 2.8vh;
  }
}

@media only screen and (min-width: 1400px) and (min-height: 901px) {
  .covidpagetitle {
    font-size: 2.3vh;
  }
}

@media only screen and (min-width: 1400px) and (max-height: 900px) {
  .covidpagetitle {
    font-size: 2.2vh;
  }
}

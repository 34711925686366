button.add-milestone-button {
    position: absolute;
    bottom: 45px;
    right: 25px;
    z-index: 1;
}

.milestone-logo-bg-2 {
    height: 100%;
    overflow-x: hidden;
    position: relative;
}
.milestone-grid {
    display: grid;
    grid-template-columns: 1fr 100px;
    position: relative;
}
.milestone-logo-bg-2:before {
    font-family: "sprxs" !important;
    font-size: 23.6rem;
    color: var(--orange);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start
}
  
.milestone-button {
    /* place-self: center start; */
    padding: 1rem 3rem;
}

.milestone-helpertext {
    color: rgba(0, 0, 0, 0.54);
}



@media (max-width: 370px) {
  
    .milestoneTitle {
      font-size: 1.1rem;
    }
    
  }
html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  /* DO NOT REMOVE - STOPS WHITE GUTTER ON RIGHT OF MOBILE VERSIONS */
  overflow-x: hidden;
}
@media (min-width: 768px) {
  html,
  body {
    overflow: unset;
  }
}

.App-style,
.app-bg {
  /* height: 100%;
  min-height: 100vh; */
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgb(255, 81, 47)),
      to(rgb(240, 152, 25))
    ),
    url(./static/bgorange2.svg);
  background-image: linear-gradient(to bottom, #ffffff, rgb(255, 255, 255)),
    url(./static/bgorange2.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;

  /*background-image: linear-gradient(
      to bottom,
      rgba(24, 85, 15, 0.932),
      rgba(45, 109, 50, 0.63)
    );*/
  /*background-color: #ffffff;*/
  position: relative;
  z-index: 1;
}

/* #main-background {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.7))), url("./containers/LandingPage/LandingPageImages/mainbodypic_max.jpg");
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url("./containers/LandingPage/LandingPageImages/mainbodypic_max.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh; */
/* width: 100%; */
/* background-attachment: fixed; */
/* z-index: 9999;
} */

/* #info-background {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.7))), url("./containers/LandingPage/LandingPageImages/Collaborate_min.jpg");
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url("./containers/LandingPage/LandingPageImages/Collaborate_min.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 80vh;
  background-attachment: fixed;
  z-index: 9999;
} */

.logo-box {
  position: absolute;
  top: 40px;
  left: 40px;
}

.logo {
  height: 100px;
  width: 120px;
  -webkit-animation-name: moveInLeft;
  animation-name: moveInLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  /*animation: App-logo-spin infinite 5s linear;*/
}

.custom-link {
  margin-top: 20px;
  margin-right: 15px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.text-box {
  text-align: center;
  -ms-flex-line-pack: center;
  align-content: center;
  position: relative;
  padding-top: 20px;
  margin-left: 10vh;
  margin-right: 10vh;
  margin-bottom: 10vh;
  margin-top: 15vh;

  /* transform: translateY(-50%) translateX(-50%); */
  /* z-index: 10; */
}

.features-icon {
  text-align: center;
  position: relative;
  top: 8vh;
  left: 50%;

  -webkit-transform: translate(-46%);

  transform: translate(-46%);
  z-index: 5;
}

.about-icon {
  text-align: center;
  position: relative;
  top: 3vh;
  left: 50%;

  -webkit-transform: translate(-50%);

  transform: translate(-50%);
  z-index: 5;
}

.footer-icon {
  text-align: center;
  position: relative;
  top: 0vh;
  left: 50%;

  -webkit-transform: translate(-50%);

  transform: translate(-50%);
  z-index: 5;
}

.heading-primary {
  color: #0f0f0f;
  position: absolute;
  /*text-transform: uppercase;*/
  margin-bottom: 40px;

  -webkit-backface-visibility: hidden;

  backface-visibility: hidden;
}
/* .heading-primary-main {
  width: 100%;
  align-content: center;
} */

.heading-primary-main p {
  text-transform: uppercase;
  position: relative;
  display: block;
  font-size: 36pt;
  font-weight: 700;
  letter-spacing: 10px;
  /*width: 50px;*/
  color: #fafafa;
  /*animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;*/
  font-display: bold;
  /* margin-top: 20%; */
  -webkit-transform: translate(0% -50%), uppercase;
  transform: translate(0% -50%), uppercase;
  /*text-shadow: 1px 3px 8px rgb(0, 0, 0);*/
  /*
animation-delay: 3s;
animation-iteration-count: 3;
*/
}

.heading-primary-sub {
  color: #fafafa;
  display: block;
  font-size: 10px;
  position: relative;
  /*font-weight: 150;*/
  letter-spacing: 5px;
  /* width: 100%; */
  -webkit-animation-name: moveInRight;
  animation-name: moveInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  /*text-shadow: 1px 1px 2px #000;*/
}

@-webkit-keyframes moveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px) rotate(0deg);
    transform: translateX(-100px) rotate(0deg);
  }

  80% {
    -webkit-transform: translateX(10px) /*rotate(180deg)*/;
    transform: translateX(10px) /*rotate(180deg)*/;
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px) rotate(0deg);
    transform: translateX(-100px) rotate(0deg);
  }

  80% {
    -webkit-transform: translateX(10px) /*rotate(180deg)*/;
    transform: translateX(10px) /*rotate(180deg)*/;
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@-webkit-keyframes moveInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }

  80% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }

  80% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@-webkit-keyframes moveInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@-webkit-keyframes moveInTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes moveInTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.custom-btn-modal:link,
.custom-btn-modal:visited,
.custom-btn:link,
.custom-btn:visited {
  /*text-transform: uppercase;*/
  font-size: 12pt;
  text-decoration: none;
  padding: 8px 8px;
  line-height: 1;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  margin-top: 2px;
  display: inline-block;
  border-radius: 5px;
  border-width: 0px;
  border-color: #ffffffff;
  border-style: solid;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  -webkit-box-shadow: 0 10px 10px #00000033;
  box-shadow: 0 10px 10px #00000033;
}

.custom-btn:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-box-shadow: 0 20px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 20px 10px rgba(0, 0, 0, 0.2);
  z-index: 50;
  color: #fff;
}

.custom-btn-modal:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-box-shadow: 0 20px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 20px 10px rgba(0, 0, 0, 0.2);
  z-index: 50;
  color: #fff;
}

.custom-btn-large:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-box-shadow: 0 40px 30px rgba(0, 0, 0, 0.2);
  box-shadow: 0 40px 30px rgba(0, 0, 0, 0.2);
  z-index: 1;
  color: #fff;
}

.custom-btn:active {
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 50;
}

.custom-btn-default {
  /*background-image: linear-gradient(
    to bottom,
    #00268d,
    rgb(2, 0, 58));*/
  background-color: #2c3e50;
  color: #ffffff;
  /*color: #f8f5f5;*/
}

.custom-btn-large:link,
.custom-btn-large:visited {
  /*background-image: linear-gradient(
    to bottom,
    #00268d,
    rgb(2, 0, 58));*/
  background-color: #2c3e50;
  color: #ffffff;
  font-size: 18pt;

  /*text-transform: uppercase;*/
  text-decoration: none;
  padding: 20px 30px;
  line-height: 1;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  margin-top: 2px;
  display: inline-block;
  border-width: 4px;
  border-radius: 4px;
  border-color: #2c3e50;
  border-style: solid;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  -webkit-box-shadow: 0 10px 10px #00000033;
  box-shadow: 0 10px 10px #00000033;
  z-index: 600;
  /*color: #f8f5f5;*/
}

.btn-fixed:link,
.btn-fixed:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 8px 8px;
  line-height: 1;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  margin-top: 2px;
  display: inline-block;
  border-radius: 100px;
  border-width: 2px;
  border-color: #ffffffff;
  border-style: solid;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-box-shadow: 0 10px 10px #00000033;
  box-shadow: 0 10px 10px #00000033;
  position: fixed;
  z-index: 8;
}

.btn-round:link,
.btn-round:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 8px 8px;
  line-height: 1;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  margin-top: 2px;
  display: inline-block;
  border-radius: 100px;
  border-width: 2px;
  border-color: #2c3e50;
  border-style: solid;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  -webkit-box-shadow: 0 10px 10px #00000033;
  box-shadow: 0 10px 10px #00000033;
  z-index: 50;
}

.btn-fixed:active,
.btn-round:active {
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 500;
}
.btn-fixed:hover,
.btn-round:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-box-shadow: 0 20px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 20px 10px rgba(0, 0, 0, 0.2);
  z-index: 500;
  color: #fff;
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.helpers--1 {
  margin-left: 30%;
}

/* .helpers--2 {
  float: center;
} */

/* .helpers--3 {
  margin-right: 10%;
} */

/* .helpers--4 {

} */

.helpers--5 {
  margin-right: 10%;
}

.helpers--1:hover #whatis,
.helpers--2:hover #makeideas,
.helpers--3:hover #blockchain,
.helpers--4:hover #october,
.helpers--5:hover #november {
  visibility: visible;
  -webkit-animation-name: appear;
  animation-name: appear;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

.custom-btn-modal {
  background-color: #2c3e50;
  color: #fff;
}

.custom-btn-blue {
  background-color: #2c3e50;
  color: #fff;
}
.custom-btn-gray {
  background-color: #757575;
  color: #fff;
}

.custom-btn-green {
  background-color: #3d7c01;
  color: #fff;
}

/* .custom-btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
} */

.custom-btn-default::after {
  background-color: rgb(247, 247, 248);
}

.custom-btn-large::after {
  background-color: rgb(247, 247, 248);
}

.custom-btn:hover::after {
  -webkit-transform: scaleX(1.4) scaleY(1.6);
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.custom-btn-large:hover::after {
  -webkit-transform: scaleX(1.4) scaleY(1.6);
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-fixed:hover::after {
  -webkit-transform: scaleX(1.4) scaleY(1.6);
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.custom-btn-animated {
  -webkit-animation: moveInBottom 0.5s ease-out 0.75s;
  animation: moveInBottom 0.5s ease-out 0.75s;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

.custom-btn-animated-top {
  -webkit-animation: moveInTop 0.5s ease-out 0.1s;
  animation: moveInTop 0.5s ease-out 0.1s;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

.custom-btn-small {
  background-color: #757575;

  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 2px 2px;
  line-height: 0.8;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  margin-top: 2px;
  display: inline-block;
  border-radius: 5px;
  border-width: 2px;
  border-color: #ffffff;
  border-style: solid;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  opacity: 0.5;
  color: #fff;
  z-index: 10;
}

.custom-btn-small:hover {
  cursor: pointer;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: -5px;
  left: 105%;

  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.custom-btn:hover .tooltiptext {
  visibility: visible;
}

/* REVISED MEDIA QUERIES */

/* Big tablet to 1200px (widths smaller than the 1140px row) */
@media only screen and (max-width: 1200px) {
}

/* Small tablet to big tablet: from 768px to 1023px */
@media only screen and (max-width: 1023px) {
  .heading-primary-main {
    /* margin-left: 5vh; */
    margin-bottom: -4vh;
  }

  .landingImageButton {
    margin-right: -3vh;
  }

  .landingImageLogo {
    margin-right: -3vh;
  }
}

/* Small phones to small tablets: from 481px to 767px */
@media only screen and (max-width: 767px) {
  /* .logo {
    height: 250px;
    width: 350px;
  } */
  /* 
  #main-background {
    background-image: no-repeat;
    width: 50;
  }

  section {
    margin-right: 0;
  } */

  /* .text-box img {
    margin-bottom: 2vh;
  } */

  .heading-primary-main {
    /* font-size: 2vh; */
    font-weight: 400;
    letter-spacing: 20px;
    text-align: center;
    margin-top: -320px;
    margin-bottom: -20px;
    font-size: 22pt;
  }

  .heading-primary-main p {
    font-size: 21pt;
  }

  .heading-primary-sub {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 27px;
    width: 105%;
  }

  .tooltiptext {
    width: 120px;
    top: 100%;
    left: 50%;
    margin-left: -60px;
  }

  .features-icon {
    top: 30vh;
    left: 39%;

    -webkit-transform: translate(-50%);

    transform: translate(-50%);
  }

  .landingImageLogo {
    margin-bottom: 280px;
  }

  .landingImageButton {
    margin-top: 10vh;
  }
  /* 
  #main-background {
    background-size: 380%;
  } */

  .text-box {
    /* margin-left: 0;
    margin-right: 0;
    margin-bottom: 10vh; */
    margin-top: -10vh;
    height: 100vh;

    /* transform: translateY(-50%) translateX(-50%); */
    /* z-index: 10; */
  }

  .player-wrapper {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

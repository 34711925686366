/* Idea List */
.invite-list {
  width: 100%;
}

.invite-list td {
  padding: 0.2rem 0.4rem;
  vertical-align: middle;
  border-bottom: 1px solid #f2f2f2;
}

.invite-list td:first-child {
  width: 60%;
}

.invite-list__name {
  color: #383736;
  font-weight: normal;
  font-size: 0.9rem;
  
}

.invite-list__collaborators {
  text-align: center; 
}

.invite-list__status {
  color: orange;
  font-size: 1rem;
  font-weight: normal;
  width: 30%;
}

.invite-list__id {
  color: #383736;
  font-size: 10px;
}

.invite-list__header {
  color: #383736;
  font-size: 12px;
  text-transform: capitalize;
}

.invite-list__expand {
  width: 22%;
}

.sprxs-idea-list-wrapper {
  margin: 0px auto;
  padding: auto 10px;
  height: 44px;
}

.invite-list__button {
  margin-left: 6px;
  color: var(--orange);
  cursor: pointer;
}

.invite-list__title{
  font-size: 1rem;
  text-align: left;
  color: #383736;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.invite-list__wrapper{
  width: 100%;
}

@media (min-width: 769px) {
  .invite-list__name {
    font-size: 1.1rem;
  }
}

.spinner-center {
  text-align: center;
  position: fixed;
  display: inline;
  top: 50%;
  left: 50%;
  z-index: 999;
  color: #000;
  /* text-shadow: 1px 2px 5px rgb(0, 0, 0); */
  background-color: rgba(240, 248, 255, 0);
  /* box-shadow:         2px 5px 20px #11111149; */
  /* animation: pulsate 1s ease-out;
  animation-iteration-count: infinite; */ 
  transform: translate(-50%, -50%);
}

@keyframes pulsate {
  0% { 
      opacity: 0.5;
  }
  50% { 
      opacity: 1.0;
  }
  100% { 
      opacity: 0.5;
  }
}
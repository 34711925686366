.Signup-style {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  position: relative;
  z-index: 1;
}

.test {
  width: 100%;
  margin: 0px;
  height: 100%;
  max-width: 100%;
  max-height: none;
}

.signup-card-style {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
  color: #000;
  z-index: 5;
}

.radioGroupOverride {
  width: 50%;
  margin-left: 0px !important;
}

.wrapperOverride {
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}

.formInner {
  height: auto;
  width: 100%;
  /* width: 400px; */
  align-items: center !important;
  text-align: center !important;
  margin: 0px auto;
}

.formIcon {
  display: inline;
  width: 64px;
  height: auto;
  padding: 8px;
  margin-right: 10px;
  margin-top: 40px;
}

.formTitle {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  margin-top: 0px;
}
.formText {
  display: block;
  font-size: 16px;
  margin-left: 74px;
  margin-right: 20px;
  margin-top: -40px;
}

.disableButton {
  display: none !important;
}

.covidspan {
  background-color: var(--red) !important;
  color: white !important;
  padding: 1px !important;
}

@media (min-width: 700px) {
  .formInner {
    height: 120px;
    align-items: center;
    cursor: pointer;
    color: #383736;
  }
}

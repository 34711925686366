.boxContainer {
    display: grid;
    width: 380px;
    height: 400px;
    background-color: #fff;
    grid-template-rows: 40px 304px 20px;
    border: 1px solid #383736;
    border-radius: 15px;
    margin: 0px 10px;
}

.boxItem {
    color: #383736;
    width: auto;
    height: auto;
    margin: 0px 0px 0px 0px;
    padding: 10px 14px 10px 14px;
    font-size: 12px;
}

.boxTitle {
    display: inline-block;
    font-size: 22px;
    font-weight: 1;
    letter-spacing: 5px;
}

.boxItem span {
    color: var(--orange);
}

.boxItem:nth-child(2) {
    text-align: left;
}

.buttonRow {
    border-top: 1px solid #383736;
}

.sectionButton {
    background-color: #1686CC;
    border: none;
    color: white;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    text-transform: uppercase;
    cursor: pointer;
}

.inactive {
    background-color: grey;
    cursor: auto;
}

.digiWrapper {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 240px 240px;
    justify-content: center;
    align-content: center;
    grid-gap: 0px;
}

.digiContainer {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 70px 50px 60px max-content;
    justify-content: center;
    align-content: center;
}

.digiContainerMin {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 54px 60px 60px;
    justify-content: center;
    align-content: center;
}

.digiContainerItem {
    color: #383736;
    text-align: center;

}

.digiContainerItem h4 {
    font-size: 24px;
    padding-top: 8px;
}

.digiContainerItem p {
    font-size: 14px;
}

.digiContainerItem img {
    max-width: 80px;
    min-width: 40px;
    height: auto;
}

@media only screen and (max-width: 599px) {
    .digiWrapper {
        grid-template-columns: auto;
        grid-template-rows: 150px 30px 180px 30px 180px 100px 150px 0px 0px 0px 0px 100px 0px 0px 0px 0px 0px 0px 80px 0px;
        justify-content: center;
        align-content: center;
        grid-row-gap: 6px;
    }

    .digiContainer {
        grid-template-rows: max-content max-content max-content;
    }

    .ideaArrow {
        background: url('../../containers/LandingPage/FrontPageV2/Images/arrow.svg') no-repeat;
        background-position-x: center;
    }
    
    .blockArrow {
        background: url('../../containers/LandingPage/FrontPageV2/Images/arrow.svg') no-repeat;
        background-position-x: center;
    }
    
    .collaborateArrow {
        background: url('../../containers/LandingPage/FrontPageV2/Images/collaborateArrowMobile.svg') no-repeat;
        background-position-x: center;
    }
    
    .monetizeArrow {
        background: url('../../containers/LandingPage/FrontPageV2/Images/monetizeArrowMobile.svg') no-repeat;
        background-position-x: center;
    }

    .digiContainerItem img {
        max-width: 60px;
        height: auto;
    }

    .digiContainerItem p {
        margin: 0px 20px;
    }

    .boxContainer {
        width: auto;
        height: auto;
        grid-template-rows: 40px max-content max-content;
        margin: 10px 10px;
    }
}

@media only screen and (min-width: 600px) {

    .digiWrapper {
        grid-template-columns: auto;
        grid-template-rows: 150px 30px 180px 30px 180px 100px 150px 0px 0px 0px 0px 100px 0px 0px 0px 0px 0px 0px 190px;
        justify-content: center;
        align-content: center;
        grid-row-gap: 0px;
    }

    .digiContainer {
        grid-template-rows: 60px 54px max-content;
    }

    .ideaArrow {
        background: url('../../containers/LandingPage/FrontPageV2/Images/arrow.svg') no-repeat;
        background-position-x: center;
    }
    
    .blockArrow {
        background: url('../../containers/LandingPage/FrontPageV2/Images/arrow.svg') no-repeat;
        background-position-x: center;
    }
    
    .collaborateArrow {
        background: url('../../containers/LandingPage/FrontPageV2/Images/collaborateArrowMobile.svg') no-repeat;
        background-position-x: center;
    }
    
    .monetizeArrow {
        background: url('../../containers/LandingPage/FrontPageV2/Images/monetizeArrowMobile.svg') no-repeat;
        background-position-x: center;
    }

    .digiContainerItem img {
        max-width: 60px;
        height: auto;
    }

    .boxContainer {
        width: 80vw;
        height: auto;
        grid-template-rows: max-content max-content max-content;
        margin: 20px 10px;
    }
}

@media only screen and (min-width: 600px) and (max-height: 420px) {

    .digiWrapper {
        padding-top: 20px;
    }

    .digiWrapper {
        grid-template-columns: auto;
        grid-template-rows: 150px 30px 180px 30px 180px 100px 150px 0px 0px 0px 0px 100px 0px 0px 0px 0px 0px 0px 150px;
        justify-content: center;
        align-content: center;
        grid-row-gap: 0px;
    }

    .digiContainer {
        grid-template-rows: max-content max-content max-content;
    }

    .ideaArrow {
        background: url('../../containers/LandingPage/FrontPageV2/Images/arrow.svg') no-repeat;
        background-position-x: center;
    }
    
    .blockArrow {
        background: url('../../containers/LandingPage/FrontPageV2/Images/arrow.svg') no-repeat;
        background-position-x: center;
    }
    
    .collaborateArrow {
        background: url('../../containers/LandingPage/FrontPageV2/Images/collaborateArrowMobile.svg') no-repeat;
        background-position-x: center;
    }
    
    .monetizeArrow {
        background: url('../../containers/LandingPage/FrontPageV2/Images/monetizeArrowMobile.svg') no-repeat;
        background-position-x: center;
    }

    .digiContainerItem p {
        width: 400px;
        font-size: 12px;
    }
}


@media only screen and (min-width: 900px) {
    .digiWrapper {
        grid-template-rows: 1fr 80px 1fr;
        grid-template-columns: 180px 80px 180px 80px 180px 80px 180px;
        grid-gap: 6px;
        grid-row-gap: 0px;
    }

    .digiContainer {
        grid-template-rows: 60px 40px 70px;
    }

    .ideaArrow {
        background: url('../../containers/LandingPage/FrontPageV2/Images/ideaArrow.svg') no-repeat;
        background-position-y: center;
        background-position-x: center;
    }
    
    .blockArrow {
        background: url('../../containers/LandingPage/FrontPageV2/Images/blockchainSecureArrow.svg') no-repeat;
        background-position-y: center;
        background-position-x: center;
    }
    
    .collaborateArrow {
        background: url('../../containers/LandingPage/FrontPageV2/Images/collaborateArrow.svg') no-repeat;
        background-position-y: center;
        background-position-x: center;
    }
    
    .monetizeArrow {
        background: url('../../containers/LandingPage/FrontPageV2/Images/monetizeArrow.svg') no-repeat;
        background-position-y: center;
        background-position-x: center;
    }

    .end {
        grid-template-rows: 40px 70px 60px;
    }

    .digiContainerItem h4 {
        font-size: 18px;
        padding-top: 8px;
    }

    .digiContainerItem p {
        font-size: 12px;
    }

    .boxContainer {
        width: 320px;
        height: 434px;
        grid-template-rows: 40px 340px max-content;
        margin: 0px 10px;
    }
}

@media only screen and (min-width: 1200px) {
    .digiWrapper {
        grid-template-rows: 1fr 100px 1fr;
        grid-template-columns: 180px 100px 180px 100px 180px 100px 180px;
        grid-gap: 10px;
        grid-row-gap: 0px;
    }

    .digiContainer {
        grid-template-rows: 58px 28px 60px;
    }

    .end {
        grid-template-rows: 28px 60px 60px;
    }

    .digiContainerItem h4 {
        font-size: 18px;
        padding-top: 8px;
    }

    .digiContainerItem p {
        font-size: 12px;
    }

    .boxContainer {
        width: 380px;
        height: 410px;
        grid-template-rows: 40px 318px 26px;
    }
}

@media only screen and (min-width: 1400px) {
    .digiWrapper {
        grid-template-rows: 1fr 100px 1fr;
        grid-template-columns: 180px 100px 180px 100px 180px 100px 180px;
    }

    .digiContainer {
        grid-template-rows: 60px 40px 90px;
    }

    .end {
        grid-template-rows: 40px 70px 60px;
    }

    .digiContainerItem h4 {
        font-size: 24px;
        padding-top: 8px;
    }

    .digiContainerItem p {
        font-size: 14px;
    }

    .boxContainer {
        width: 420px;
        height: 480px;
        grid-template-rows: 40px 380px 20px;
        margin: 0px 10px;
    }

    .boxItem {
        font-size: 14px;
    }
}
.revealLink{
  color: var(--orange);
  cursor: pointer;
  margin-left: 10px;
}
.revealLink:hover {
  color: #383736;
}

.revealText {
  color: #383736;
  font-size: 0.8rem;
  margin-left: 10px;
}

.formButtons {
  margin-left: 10px;
  margin-bottom: 10px;
}

.buttonBorder {
  background-color: rgba(56,55,54, 0.1);
  width: 96%;
  height: 32px;
  padding: 4px;

}

.accountWarning {
  margin-left: 10px;
  color: #ea2424;
}

.textEnabled {
  user-select: all;
}

.textDisabled {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
  opacity: 0.5;
  transition: 0,5ms;
}
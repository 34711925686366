.my-profile {
  display: inline-block;
  position: relative;
  text-align: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  color: #383736;
}

.my-profile__header {
  height: 80px; 
  margin-bottom: 10px;
}

.my-profile__public{
  float: left;
  margin-right: -20px;
}

.my-profile__card {
  padding-bottom: 0px !important;
  text-align: left;
}

.my-profile__name {
  display: block;
  text-align: left;
  margin: 0px;
  padding: 0px;
  padding-top: 4px;
  font-size: 1.2rem;
}

.my-profile__card-header {
  padding-left: 10px;
  font-size: 1rem !important;
  margin-bottom: 8px;
}

.my-profile__card-subheader {
  font-size: 1rem !important;
}

.my-profile__card-body {
  padding-left: 20px;
  font-size: 0.8rem;
  text-align:left;
  padding-bottom: 10px;
  border-bottom: 1px solid #e6e9ec;
}

.my-profile__card-skill {
  font-size: 0.8rem !important;
}

.my-profile__icon {
  color: var(--orange);
  margin: 16px;
  cursor: pointer;
}

.my-profile__primaryExpertise {
  margin: 0px;
  padding: 0px;
  text-align: left; 
  font-size: 1rem;
}

.my-profile__secondaryExpertise {
  margin: 0px;
  padding: 0px;
  text-align: left;
  font-size: 0.8rem;
}

.avatar {
  display: inline-grid;
  margin: 10px;
  width: 60px !important;
  height: 60px !important;
  float: left;
}
.Signup-style {
    height: 100%;
    min-height: 100vh;
    width: 100%;
    position: relative;
    z-index: 1;
  }
  
  .signup-card-style {
    animation: moveInBottom 0.5s ease-out 0.75s;
    animation-fill-mode: backwards;
    color: #000;
    z-index: 5;
  }
  
  .radioGroupOverride {
    width: 50%;
    margin-left: 0px !important;
  }
  
  .wrapperOverride{
    flex-direction: row !important;
    flex-wrap: nowrap !important;
  }
  
  .formInner{
    height: 200px;
    align-items: center;
    cursor: pointer;
    color: #383736;
    border: 0px solid green;
  }
  
  .formIcon {
    display: inline;
    width: 64px;
    height: auto;
    padding: 8px;
    margin-right: 10px;
    margin-top: 40px;
  }
  
  .formTitle{
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    margin-top:  0px;
  }
  .formText{
    display: block;
    font-size: 16px;
    margin-left: 74px;
    margin-right: 20px;
    margin-top: -40px;
  }
  
  .disableButton{
    display: none !important;
  }
        
  @media (min-width: 700px) {
  
    .formInner{
      height: 120px;
      align-items: center;
      cursor: pointer;
      color: #383736;
    }
  }
  
  
.wallet__progress-bar-foreground {
  height: 20px;
  background-color: #ef8406;
  border-radius: 50px;
}
.wallet__progress-text {
  margin: 0 1rem;
  text-align: right;
  line-height: 20px;
  font-size: 0.9rem;
  color: #f2f2f2;
}
.wallet__progress-bar-background {
  /* width: 100%; */
  height: 20px;
  margin: 0.8rem;
  background-color: #edaa5b;
  /* box-shadow: 2px 2px 0 #ccc; */
  border-radius: 50px;
}

.wallet__title {
  margin-left: 0.8rem;
  font-size: 0.8rem;
  font-weight: normal;
  text-align: left;
}

.wallet__title-idea {
  margin-left: 0.8rem;
  margin-top: 1rem;
  font-size: 0.8rem;
  font-weight: normal;
  text-align: left;
}

.wallet__wrapper {
  flex-basis: 100%;
}

/* Idea List */
.idea-list {
  width: 100%;
}

.idea-list td {
  padding: 0.2rem 0.4rem;
  vertical-align: middle;
  border-bottom: 1px solid #f2f2f2;
}

.idea-list-collab {
  width: 100%;
}

.idea-list-collab td {
  padding: 0.2rem 0.4rem;
  vertical-align: middle;
  border-bottom: 1px solid #f2f2f2;
}

.idea-list td:first-child {
  width: 5%;
}

.idea-list-collab td:first-child {
  width: 70%;
}

.idea-list__name {
  color: #383736;
  font-weight: normal;
  font-size: 0.9rem;
}

.idea-list__collaborators {
  text-align: center; 
}

.idea-list__collaborators,
.idea-list__equity {
  color: orange;
  font-size: 1rem;
  font-weight: normal;
  width: 10%;
}

.idea-list__id {
  color: #383736;
  font-size: 10px;
}

.idea-list__header {
  color: #383736;
  font-size: 12px;
  text-transform: capitalize;
}

.sprxs-idea-list-wrapper {
  margin: 0px auto;
  padding: auto 10px;
  height: 44px;
}

.idea-list__button {
  margin-left: 6px;
  color: var(--orange);
  cursor: pointer;
}

.idea-list__wrapper{
  width: 100%;
}

@media (min-width: 769px) {
  .idea-list__name {
    font-size: 1.1rem;
  }
}

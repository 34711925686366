.milestone-list__wrapper {
  flex-basis: 100%;
}

.milestone-list__divider {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
}

.milestone-list__bold {
  font-weight: bold;
}

.milestone-list__button {
  margin-right: 6px;
  color: var(--orange);
}

.milestone__edit {
  margin-right: 0px;
  float: right;
  cursor: pointer;
}

.codeGreen {
  color: green;
}

.codeRed {
  color: red;
}
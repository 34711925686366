.headerNavContainer {
    display: grid;
    width: 280px;
    height: auto;
    grid-template-rows: 40px 60px auto;
    justify-content: center;
    margin: 0px 8px;
    /* cursor: pointer; */
}

.headerNavContainer img {
    max-width: 60px;
    min-width: 40px;
    height: auto;
    opacity: 1;
    transition: 0.5s;
}

.headerNavContainer p {
    font-size: 14px;
    opacity: 0;
    transition: 0.5s;
}

.headerNavContainer .short {
    font-size: 14px;
    opacity: 0;
    transition: 0.5s;
}

.headerNavContainer:hover img {
    opacity: 1;
}

.headerNavContainer:hover p {
    opacity: 1;
}

.headerNavContainer:hover .navMore {
    display: inline-block;
}

/* .headerNavContainer:hover .headerNavLink {
    color: var(--orange);
} */

.headerNavItem {
    color: #383736;
    justify-content: center;
    width: 100%;
    padding: 0px 10px 0px 10px;
}

.headerNavLink {
    /* cursor: pointer; */
    display: inline-block;
    font-size: 18px;
    font-weight: 1;
    letter-spacing: 5px;
    transition: 0.3s;
}

.headerNavText {
    width: auto;
}

.navMore {
    display: inline-block;
    width: 14px;
    height: 14px;
    opacity: 0.6;
    transform: rotate(-45deg);
    box-sizing: border-box;
    border-left: 1px solid var(--orange);
    border-bottom: 1px solid var(--orange);
    -webkit-animation: sdb04 4.2s;
    animation: sdb04 4.2s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-delay: 2s;

    animation-iteration-count: 1;
    animation-delay: 2s;
  }

  @-webkit-keyframes sdb04 {
    0% {
      -webkit-transform: rotate(-45deg) translate(0, 0);
      display: inline-block;
      opacity: 0.6;
    }
    20% {
      -webkit-transform: rotate(-45deg) translate(-10px, 10px);
    }
    40% {
      -webkit-transform: rotate(-45deg) translate(0, 0);
    }
    40% {
        -webkit-transform: rotate(-45deg) translate(0, 0);
        opacity: 0;
      }
      100% {
        display: inline-block;
      }
  }
  @keyframes sdb04 {
    0% {
      transform: rotate(-45deg) translate(0, 0);
      display: inline-block;
      opacity: 0.6;
    }
    20% {
      transform: rotate(-45deg) translate(-10px, 10px);
    }
    40% {
      transform: rotate(-45deg) translate(0, 0);
      opacity: 0;
    }
    100% {
        display: inline-block;
      }
    
  }



.fade-appear {
    opacity: 1;
  }
  
  .fade-appear.fade-appear-active {
    opacity: 0;
    transition: opacity 8000ms ease-in-out;
  }

  @media only screen and (max-width: 320px) {
    .headerNavContainer {
        display: grid;
        width: 280px;
        min-height: 0px;
        height: 20px;
        grid-template-rows: 20px 20px 0px;
        grid-row-gap: 6px;
        justify-content: center;
    }

    .headerNavItem {
        color: #383736;
        justify-content: center;
        width: auto;
        padding: 0px 10px 0px 10px;
        height: 20px;
    }

    .headerNavContainer img {
        display: inline-block;
    }

    .headerNavContainer p {
        display: inline-block;
    }

    .headerNavLink {
        /* cursor: pointer; */
        display: inline-block;
        font-size: 12px;
        font-weight: 1;
        letter-spacing: 5px;
        border: 1px solid var(--orange);
        padding: 4px;
        border-radius: 15px;
    }

    .navMore {
        display: inline-block;
    }
  }

  @media only screen and (min-width: 321px) and (max-width: 599px) {
    .headerNavContainer {
        display: grid;
        width: 280px;
        min-height: 0px;
        height: 20px;
        grid-template-rows: 20px 20px 0px;
        grid-row-gap: 6px;
        justify-content: center;
    }

    .headerNavItem {
        color: #383736;
        justify-content: center;
        width: auto;
        padding: 0px 10px 0px 10px;
        height: 20px;
    }

    .headerNavContainer img {
        display: inline-block;
    }

    .headerNavContainer p {
        display: inline-block;
    }

    .headerNavLink {
        /* cursor: pointer; */
        display: inline-block;
        font-size: 12px;
        font-weight: 1;
        letter-spacing: 5px;
        border: 1px solid var(--orange);
        padding: 6px;
        border-radius: 15px;
    }
    .navMore {
        display: inline-block;
    }
}

@media only screen and (max-width: 599px) and (min-height: 300px) {
    .headerNavContainer {
        width: 180px;
    }
}

@media only screen and (min-width: 600px) {
    .headerNavContainer {
        display: grid;
        width: 280px;
        height: 80px;
        grid-template-rows: 10px;
        justify-content: center;
    }

    .headerNavItem {
        color: #383736;
        justify-content: center;
        width: auto;
        padding: 0px 10px 0px 10px;
        
    }

    .headerNavLink {
        font-size: 16px;
        border: 1px solid var(--orange);
        padding: 6px;
        border-radius: 15px;
    }

    .headerNavContainer img {
        display: inline-block;
    }

    .headerNavContainer p {
        display: inline-block;
    }
    .navMore {
        display: inline-block;
    }
}

@media only screen and (min-width: 600px) and (max-height: 380px) {
    .headerNavContainer {
        width: 180px;
    }
    .headerNavLink {
        font-size: 12px;
        border: 1px solid var(--orange);
        padding: 6px;
        border-radius: 15px;
    }
}

@media only screen and (min-width: 900px) {
    .headerNavContainer {
        height: 200px;
        grid-template-rows: 40px 60px 100px;
    }

    .headerNavLink {
        font-size: 16px;
        border: 0px solid var(--orange);
        padding: 6px;
        border-radius: 15px;
    }

    .headerNavContainer img {
        display: inline;
    }

    .headerNavContainer p {
        display: inline;
    }
    .navMore {
        display: inline-block;
    }
}

@media only screen and (min-width: 700px) and (max-height: 420px) {
    .headerNavContainer {
        width: 180px;
    }

    .headerNavLink {
        font-size: 12px;
        border: 1px solid var(--orange);
        padding: 6px;
        border-radius: 15px;
    }
}

@media only screen and (min-width: 1200px) {
    .headerNavContainer {
        grid-template-rows: 30px 40px 100px;
    }

    .headerNavLink {
        /* cursor: pointer; */
        display: inline-block;
        font-size: 16px;
        font-weight: 1;
        letter-spacing: 5px;
    }

    .headerNavContainer img {
        max-width: 40px;
        min-width: 40px;
        height: auto;
    }

}

@media only screen and (min-width: 1400px) and (min-height: 900px) {
    .headerNavContainer {
        grid-template-rows: 30px 64px 100px;
    }

    .headerNavLink {
        /* cursor: pointer; */
        display: inline-block;
        font-size: 18px;
        font-weight: 1;
        letter-spacing: 5px;
    }

    .headerNavContainer img {
        max-width: 60px;
        min-width: 60px;
        height: auto;
    }

    .headerNavContainer p {
        font-size: 15px;
    }
}
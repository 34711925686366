.Homepage-style {
  height: 100%;
  min-height: 100vh;
  /*background-image: linear-gradient(
      to top,
      rgba(16, 21, 32, 0.7),
      rgba(45, 39, 66, 0.7)
    ),
    url(../../static/bg6.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;*/
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}

/*.row:last-child{
  margin: 5rem;

}
*/

#page-container {
  padding-bottom: 40px;
}

.container {
  /*background-color: aquamarine;*/
  width: 90%;

  display: grid;
  max-height: 80vh;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
  grid-column-gap: 30px;

  margin: 0 auto;
  /*z-index: auto;*/
  margin-bottom: 0px;

  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;

}

.item {
  padding: 20px;
  font-size: 20px;
  color:black;
  border-radius: 2px; 
  box-shadow: 1px 1px 8px rgb(0, 0, 0);
  transform: translate(0%, 0%);
  z-index: auto;
}

#item--1 {
  background-color: rgb(245, 245, 245);
  font-size: 15px;
}

#item--2 {
  background-color: rgba(245, 245, 245, 0);
  font-size: 15px;
  grid-column: span 2;
}
#item--3 {
  background-color: rgb(245, 245, 245);
}
#item--4 {
  background-color: rgb(245, 245, 245);
}
#item--5 {
  background-color: rgb(245, 245, 245);
}
#item--6 {
  background-color: rgb(245, 245, 245);
}

.profile-table {
  margin-left:10%;
  margin-right:10%;
  visibility: hidden;

}

@media (min-width: 700px) {

  .profile-table {
    margin-left:10%;
    margin-right:10%;
    visibility: visible;
    position: relative;
    z-index: 99;
  }

  .container {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .item {
    transform: translate(0%, 0%);
  }
}

.create-idea-modal{
  background-color: rgb(255, 255, 255);
  border-radius: 25px;
  font-size: 20pt;
}

.create-idea-modal-header{
  text-align: center;
  color: "#2c3e50";
  border: 0;
  margin-left: 0%;
  position: relative;
  z-index: 9999;
  
}

.coin-spin{
  margin-left: 50%;
  position: relative;
  margin-bottom: 10;
  margin-top: 10;
  transform: translate(-50%);
  perspective: 9999px;
  animation: spin 5s infinite linear;
}

.createIdeaGrid{
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  margin: 40px 40px 0px 40px;
  justify-content: center;
}

.createIdeaGrid div {
  color: #383736;
  border: 0px solid red;
}

.ideaSummary{
  color: black;
}

.roundCircle{
  border-radius: 50px;
  border: 2px solid green;
  color: green !important;
}

.formIcons__1{
  font-size: 60px !important;
  margin-left: 50%;
  position: relative;
  transform: translate(-50%);
  margin-bottom: 10px;
}

.red{
  color: red;
}

.createIdeaGrid h1{
  color: var(--orange);
  font-size: 32px;
}

.ideaSummary span{
  color: var(--orange);
}

.confirmation {
  font-size: 18px;
  padding: 20px;
}

.submitMessage{
  margin-top: 16px;
}

.disableButton{
  display: none !important;
}

@keyframes spin {
  0% {
    transform:translate(-50%) rotateY(0deg);
     }
  100% {
    transform:translate(-50%) rotateY(360deg);
    }
}

@media (min-width: 450px) {
  .create-idea-modal-header {
    /*margin-left: 50%;*/
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

body {
  /*font-family: 'Roboto Condensed', sans-serif;*/
  /*font-family: 'Courier New', Courier, monospace;*/
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  color: rgb(255, 255, 255);
  padding: 0 !important;

  /*font-family: europa,"Avenir Next",Avenir,"Helvetica Neue",Helvetica,Arial,sans-serif;*/
  /*font-family:  'Lato', sans-serif;*/
  /* font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  font-family: "Open Sans", sans-serif;
  font-family: "PT Sans", sans-serif;*/
  font-family: "Ubuntu", sans-serif; 
  /* font-family: "Chakra Petch", sans-serif; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

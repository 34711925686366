.iconDefault {
  color: var(--orange);
  cursor: pointer;
  display: inline-block !important;
  border: 0px solid blue;
}

.iconAction {
  border: 0px solid blue;
  display: inline-block !important;
  cursor: pointer;
  color: var(--orange);
}

.iconAction:hover {
  cursor: pointer;
  color: #383736;
}

.iconDisabled {
  border: 0px solid blue;
  display: inline-block !important;
  cursor: auto !important;
  color: #383736;
}

.iconCodeGreen {
  color: #4cea24;
}

.iconCodeBlue {
  color: #1686cc;
}

.iconCodeBlack {
  color: #383736;
}

.iconWarning {
  color: #ea2424 !important;
}

.iconRight {
  float: right;
}

.iconRightCorner {
  margin: 6px;
}

.iconPadRight { 
  margin-right: 4px;
}

.iconPadLeft { 
  margin-left: 10px;
}


@media (min-width: 768px) {
  .iconPadRight { 
    margin-right: 10px;
  }
}

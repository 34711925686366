.compContainer {
  display: grid;
  width: 100%;
  /* height: 190px; */
  height: 140px;
  /* height: auto; */
  position: sticky;
  bottom: 0px;
  align-items: center;
  justify-content: start;
  text-align: left;
  padding: 0px 0px 0px 0px;
  line-height: 56px;
  grid-template-columns: auto 400px 400px 1fr;
  grid-template-rows: auto auto;
  grid-row-gap: 1%;
  background-color: var(--red);
  z-index: 3;
}

.compButton {
  display: inline-block;
  background-color: var(--red);
  border: 2px solid #fff;
  color: #fff;
  /* padding: 14px 12px; */
  padding: 1% 6% 0% 1%;
  /* width: 140px; */
  width: 100%;
  text-align: center;
  text-decoration: none;
  font-size: 2.5vh;
  border-radius: 25px;
  line-height: 0px;
  text-transform: uppercase;
  cursor: pointer;
}
.compButton:hover {
  background-color: var(--blue) !important;
}

.compSocial {
  width: 100%;
  text-align: right;
}

.compIcon {
  margin: 0px 8px 0px 8px;
  color: #fff;
  cursor: pointer;
}

.compContainer p {
  width: 100%;
  line-height: 12%;
  word-break: break-all;
  word-wrap: break-word;
  margin: 0px;
  padding: 0px;
}

@media only screen and (max-width: 599px) {
  .compContainer {
    padding: 2px 4px 2px 4px;
    grid-template-rows: 55px auto;
    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
    grid-row-gap: 0.3%;
    align-items: center;
    justify-content: start;
    /* text-align: center; */
    grid-auto-flow: column;
    line-height: 0px;
  }

  .compSocial {
    visibility: hidden;
    /* width: 100%;
    text-align: center;
    justify-content: center; */
  }

  .compIcon {
    margin: 0px 3px 0px 3px;
    color: #fff;
    cursor: pointer;
  }
  .compContainer-para {
    grid-column-end: span 3;
    width: 100%;
    max-width: 400px;
    word-break: break-all;
    word-wrap: break-word;
    line-height: 0px;
    font-size: 1.9vh;
    margin: auto;
    padding: 20px 1px 0px 12px;
    justify-content: start;
    text-align: start;
    align-items: stretch;
  }

  .compButton {
    /* background-color: #1686cc; */
    background-color: var(--red);
    margin-top: -5px;
    margin-bottom: 3px;
    border: 2px solid #fff;
    color: #fff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    margin-top: 25px;
    display: inline-block;
    font-size: 2.2vh;
    border-radius: 15px;
    line-height: 0px;
    width: 170px;
    max-width: 170px;
    text-transform: uppercase;
    cursor: pointer;
  }
  .compButton:hover {
    background-color: var(--blue) !important;
  }

  .compIcon {
    margin: 0px 4px 0px 4px;
    color: #fff;
    cursor: pointer;
  }
}

@media only screen and (min-width: 600px) {
  .compContainer {
    padding: 0px 5px 0px 5px;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto 2fr 1fr;
    grid-auto-flow: row;
    width: auto;
    height: auto;
    overflow: hidden;
  }

  .compButton {
    padding: 14px 12px;
    /* width: 120px; */
    width: auto;
    margin-right: 2%;
    font-size: 12px;
  }
  .compButton:hover {
    background-color: var(--blue) !important;
  }

  .compContainer p {
    line-height: 20px;
    font-size: 2.5vh;
    text-align: left;
    margin-right: 8px;
  }
}

@media only screen and (min-width: 1024px) {
  .compContainer {
    padding: 0px 10px 0px 10px;
    grid-template-rows: 60px;
    grid-template-columns: 120px 450px 2fr 1fr;
    grid-auto-flow: row;
    width: auto;
  }

  .compButton {
    width: 150px;
    max-width: 150px;
  }
  .compButton:hover {
    background-color: var(--blue) !important;
  }
}

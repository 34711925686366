.faqWrapper{
  margin-left: 15%;
  margin-right: 15%;
  color:  #383736;
  background-color: white;
}

.faqHeader{
  padding-bottom: 40px;
}

.red{
  color: red;
}

.faqContent ul{
  list-style-position: outside;
  font-size: 1.2rem;
  padding: 8px 20px 0px 20px;
}

.faqContent ol {
  font-size: 1.2rem;
}

.faqContent ul {
  list-style-type: disc;
}

.faqContent ol li {
  padding: 6px 0px;
}

.faqContent .listSmall {
  font-size: 1rem;
  padding-top: 0px;
}

.faqContent .faqAlt {
  list-style-type: lower-roman;
}

.faqContent .faqAlpha {
  list-style-type: lower-alpha;
}

.faqSubHead {
  font-size: 24px;
  color: var(--orange);
}
.faqContent span {
  font-weight: 600;
}

.faqContent .copySubHead {
  content: "";
  display: block;
  padding: 8px 0px;
}

.faqContent .highLight{
  color: var(--orange);
}

.faqContent .buffer { 
  margin-bottom: 5rem;
}

.faqCopy{
  font-size: 1rem;
}

.questionLink {
  color: var(--orange);
  font-size: 1.2rem;
}

.questionLink:hover {
  color:  #383736;
}


.faqModal .faqWrapper{
  margin-left: 0%;
  margin-right: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
}

.faqHeader{
  padding-bottom: 40px;
  font-size: 30px;
  padding-top: 10%;
  font-size: 2.5rem;
}

@media only screen and (max-width: 700px) {
  .faqWrapper{
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 5%;
    font-size: 12px;
  }
  .faqHeader{
    padding-bottom: 20px;
    padding-top: 20%;
    font-size: 1.1rem;
  }

  .faqSubHead {
    font-size: 1.1rem;
  }

  .faqContent ul, ol{
    padding: 4px 14px 0px 24px;
  }
  
  .faqContent ol li {
    padding: 4px 0px;
  }

  .faqContent .copySubHead {
    padding: 4px 0px;
  }

  .questionLink {
    color: var(--orange);
    font-size: 1.1rem;
  }
}

.SearchResultsPage-style {
  /* height: 100%;
  min-height: 100vh; */
  /*background-image: linear-gradient(
      to top,
      rgba(16, 21, 32, 0.7),
      rgba(45, 39, 66, 0.7)
    ),
    url(../../static/bg6.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;*/
  background-color: #ffffff00;
  position: relative;
  margin-top: 0px;
  z-index: 3;
}

/*.row:last-child{
  margin: 5rem;

}
*/

/*#page-container {
  height: 150%;
  display: block;
}
*/

.card-style {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
  z-index: 3;
}

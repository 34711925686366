:root {
  --gap-separator: 8px;
  --gradient-height: 50px;
}

.avatar-and-name {
  display: flex;
  /* margin: 0 auto; */
  align-items: center;
}

div.chat-header .chat-main__user-name {
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}

div.user-chat-card {
  padding: 0;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  padding: 0 15px;
}

div[class*="ChatMain-bigAvatar"] {
  margin: 0 var(--gap-separator);
}

/* .chat-modal > div:first-child {
  margin: var(--gap-separator);
} */

#toggle-chat {
  display: none;
}

#toggle-chat + .chat-modal-wrapper {
  left: -150%;
  right: 150%;
}
#toggle-chat:checked + .chat-modal-wrapper {
  left: 0%;
  right: 0%;
}
.chat-modal-wrapper {
  position: fixed;
  background-color: #e1e1e1;
  /* background-color: rgba(255, 255, 255, 1); */
  box-shadow: 2px 2px 0 rgba(255, 255, 255, 0.9);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* padding: 15px; */
  z-index: 98765;
  /* padding: var(--gap-separator); */
  left: -100%;
  transition: 0.2s;
  transition-timing-function: ease-out;
}

.chat-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-header .sprxs-gender-neutral::before {
  color: var(--blue);
}

.chat-profile,
.chat-header {
  background-color: #eee;
}

.chat-profile,
.chat-sidebar,
.chat-action,
.chat-message-list {
  /* padding: var(--gap-separator); */
  border: 1px solid #e0e0e0;
}

.chat-sidebar,
.chat-action,
.chat-message-list {
  display: none;
}

.chat-message-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.icon-wrapper {
  margin: 0 1rem;
}

.chat-main__user-name {
  font-size: 1rem;
  height: 2.5rem;
  flex-grow: 1;
  color: var(--dark);
  text-transform: capitalize;
  padding: 0.2rem 0.6rem;
  display: flex;
  align-items: center;
}

.one-line-desc {
  font-size: 0.8rem;
  color: var(--dark);
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 35vw;
}

.chat-header {
  /* flex-grow: 1; */
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
}

.chat-message-list [class*="WithMobileDialog"] {
  border-radius: 4px;
  flex-grow: 1;
  padding-top: 1rem;
  padding-top: var(--gradient-height);
}

.chat-message-list [class*="WithMobileDialog"],
.chat-message-list [class*="WithMobileDialog"] > div[class^="ChatMessages"] {
  background-color: #efe6dd;
}

.chat-message-list
  [class*="WithMobileDialog"]
  > div[class^="ChatMessages"]::before {
  content: "\E900";
  font-size: 2.5rem;
  color: var(--orange);
  font-family: "sprxs" !important;
  content: "\E900";
  font-size: 25vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0.8;
}

.chat-message-list > div:first-child {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  position: relative;
}

.chat-message-list > div:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--gradient-height);
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    white 15%,
    rgba(255, 255, 255, 0) 100%
  );
}

.chat-profile > div:first-child {
  height: 100%;
  display: flex;
  /* flex-direction: column; */
}

.user-chat-mobile-menu,
.user-chat-mobile-menu-active {
  flex-grow: 1;
  text-align: center;
  background-color: var(--orange);
  cursor: pointer;
}

.user-chat-mobile-menu-active {
  color: var(--gray-dark);
  background-color: var(--white);
}

.user-chat-mobile-menu:hover {
  background-color: var(--blue);
}

.chat-profile > div:first-child > div:first-child {
  /* flex-grow: 1; */
}

input[name="toggle-card-group"] {
  display: none;
}

input[name="toggle-card-group"]:checked + div {
  display: block;
}

label[for*="toggle-card"] {
  padding: 0.5rem 1rem;
}

label[for*="toggle-card"]:hover {
  background-color: #fff;
  color: black;
}

.chat-mobile-menu {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  .chat-header,
  .chat-profile {
    padding: 1rem 0;
  }
}

@media screen and (min-width: 768px) {
  .chat-modal {
    display: grid;
    /* grid-gap: var(--gap-separator); */
    grid-template-columns: 420px 1fr;
    grid-template-rows: 60px 1fr;
  }

  .chat-message-list {
    /* grid-column: 2/3;
    grid-row: 1/3; */
  }

  .chat-sidebar {
    background-color: #fbfbfb;
    overflow-y: scroll;
  }

  .chat-sidebar,
  .chat-action,
  .chat-message-list {
    display: block;
  }

  .chat-mobile-menu {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .chat-modal-wrapper {
    padding: 1rem;
    position: fixed;
  }

  .chat-modal-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 97px;
    background-color: var(--gray);
    z-index: -1;
  }

  .chat-modal {
    border-radius: 10px;
    overflow: hidden;
    width: 1280px;
    margin: 0 auto;
    box-shadow: 2px 2px 6px var(--gray);
  }
}

/* Idea List */
.inbox-list {
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto 1fr;
}

.inbox-list__name {
  color: gray;
  font-weight: bold;
  font-size: 1rem;
  white-space: nowrap;
}

.inbox-list__message {
  /* text-align: justify; */
  font-size: 0.95rem;
  grid-column: span 2;
  padding: 0 0.5rem 0.5rem;
  border-bottom: 1px solid #f2f2f2;
}

.inbox-list__message:last-of-type {
  border-bottom: 0;
}

.inbox-list__id {
  color: gray;
  font-size: 12px;
}

.inbox-list__header {
  color: gray;
  font-size: 12px;
  text-transform: capitalize;
}

.sprxs-inbox-list:before {
  content: "\e900";
  font-size: 2.5rem;
  color: white;
  background-color: #fd7e14;
  border-radius: 50%;
  padding: 0.4rem;
}

td.sprxs-inbox-list-wrapper {
  margin: 10px auto;
  padding: 0 10px;
  height: 64px;
  vertical-align: middle;
}

.inbox-list tr:not(:last-of-type) {
  border-bottom: 1px solid gray;
}

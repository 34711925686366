.errorWrapper{
  width: 100%;
  margin-top: 5%;
  color: #383736;
  text-align: center;
  padding-top: 10vh;
  height: 80vh;
}

.warning{ 
  font-size: 10rem;
  color: var(--orange);
}

.errorLink {
  color: var(--orange);
}

.errorLink:hover {
  color: #383736;
}